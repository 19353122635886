import { destinationsStore } from "@root/framework/stores/app-store";
import { sendGet } from "@root/services/httpService";
import { useEffect } from "react"
import { userRootDataStore } from "@root/framework/stores/user-store";
import { useStore } from "@nanostores/react";

const DestinationsLoader = () => {
    const $userRootData = useStore(userRootDataStore);

    useEffect(() => {
        if (!$userRootData?.areasProfiles) return;

        if (!$userRootData.areasProfiles.find(area => area.profile == "INTERCOM")) {
            console.warn("El usuario no tiene el perfil INTERCOM asignado");
            return;
        }

        const intercomAreaId = $userRootData.areasProfiles.find(area => area.profile == "INTERCOM")?.areaId;

        if (intercomAreaId) {
            sendGet(`${import.meta.env.PUBLIC_INTERCOM_API_URL}/v2/areas/${intercomAreaId}`)
                .then(res => res.json())
                .catch(err => console.log(err))
                .then((data) => {
                    sendGet(`${import.meta.env.PUBLIC_INTERCOM_API_URL}/v2/areas/${data.globalAreaId}/data/apps/intercom/callbook`)
                        .then(res => res.json())
                        .catch(err => console.log(err))
                        .then((data) => {
                            destinationsStore.set(data)
                        })
                        .catch(err => console.log(err))
                })
                .catch(err => console.log(err));
        }
    }, [$userRootData]);
}

export default DestinationsLoader